import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { VehicleService } from '../../../../services/vehicle.service';
import { AppointmentService } from '../../../../services/appointment.service';
import { ShedulingData } from '../../../../models/schedule.model';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-onlinezakazivanje',
  standalone: true,
  imports: [FormsModule, CommonModule, NgbModule],
  templateUrl: './onlinezakazivanje.component.html',
  styleUrl: './onlinezakazivanje.component.scss'
})
export class OnlinezakazivanjeComponent {
  showShadow = false;
  termini: Date[] = [];
  myVehicles: any[] = [];
  public data = new ShedulingData();
  step: number = 0
  constructor(private toastr: ToastrService, private vehicleService: VehicleService, private authService: AuthService, private appointmentService: AppointmentService, private router: Router) {


  }
  minDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
  maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() + 15 };

  ngOnInit(): void {
    this.getVehicles();
    this.getCurrentUserData();
  }
  changeState(number: Number) {
    if (number === 1) {
      this.showShadow = true;
    }
    if (number === 2) {
      this.showShadow = false;
    }
  }
  navigate(url: string) {
    this.router.navigate([url]).then(() => {
      window.scrollTo(0, 0);
    });
  }

  getCurrentUserData(){
    if (this.isLoggedIn()) 
    {
      let user = this.authService.getCurrentUser();
      if(user)
      {
        this.data.firstName = user.firstName;
        this.data.lastName = user.lastName;
        this.data.email = user.email;
        this.data.phone = user.phone;
      }
      else
        return;

    }
  }

  getVehicles() {
    if (this.isLoggedIn()) {
      this.vehicleService.getForOptions().subscribe((response: any) => {
        if (response.status == 200) {
          this.myVehicles = response.data;
        }
        else {
          this.toastr.error(response.message);
          return;
        }
      }, (error: any) => {
        this.toastr.error('Došlo je do greške.');
      });
    }
    else {
      this.myVehicles = [];
    }
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  isValid(){
    if (this.step <= 2) {
      if (this.step == 0) {
        if (this.data.tipVozila != 1 && this.data.tipVozila != 2) {
          this.toastr.error('Molimo odaberite odgovarajući tip vozila');
          return false;
        }
        if (this.isLoggedIn() && this.data.VehicleId == 0) {
          this.toastr.error('Molimo odaberite neko od Vaših vozila');
          return false;
        }
      }
      else if (this.step == 1) {
        if (this.data.lastName == '' || this.data.firstName == '') {
          this.toastr.error('Molimo unesite ime i prezime.')
          return false;
        }
        if (this.data.email != '') {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const isValidEmail = emailRegex.test(this.data.email);

          if (!isValidEmail) {
            {
              this.toastr.error('Molimo unesite ispravan email adresu.')
              return false;
            }
          }
        }
        else {
          this.toastr.error('Molimo unesite email adresu.')
          return false;
        }
        if (this.data.phone != '') {
          const phoneRegex = /^[+]?[\d\s-]{7,15}$/;
          const isValidPhone = phoneRegex.test(this.data.phone);

          if (!isValidPhone) {
            this.toastr.error('Molimo unesite ispravan broj telefona. (7-15 cifara)')
            return false;
          }
        }
        else {
          this.toastr.error('Molimo unesite broj telefona.')
          return false;
        }
      }
      else if (this.step == 2) {
        if (this.data.tempdatum.getFullYear() < 2024) {
          this.toastr.error('Molimo odaberite datum.')
          return false;
        }
        if (this.data.tempdatum.getHours() == 0) {
          this.toastr.error('Molimo odaberite termin.')
          return false;
        }
      }

      if (this.step != 2)
      {
        this.step++;
        return false;
      }
      return true;
    }
    return false
  }

  save() {
    //koraci
    if(!this.isValid())
      return;

    this.data.datum = this.data.tempdatum;
    this.appointmentService.save(this.data).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message)
        this.router.navigate(['/']).then(() => {
          window.scrollTo(0, 0);
        }); return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }

  onDateChange(event: any) {
    this.data.tempdatum = new Date(event.year, event.month - 1, event.day + 1);
    this.appointmentService.getFreeForDate(this.data.tempdatum).subscribe((response: any) => {
      if (response.status == 200) {
        this.termini = response.data.map((dateString: string) => new Date(dateString));
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
  selectAppointment(appoint: Date) {
    this.data.tempdatum = appoint;
  }
}
