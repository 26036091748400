import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { VehicleService } from '../../../../services/vehicle.service';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { VehicleDataIn } from '../../../../models/vehicle.model';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-vehicle-crud',
  standalone: true,
  providers: [DatePipe],
  imports: [FormsModule, CommonModule],
  templateUrl: './vehicle-crud.component.html',
  styleUrl: './vehicle-crud.component.scss',
})
export class VehicleCrudComponent implements OnInit {
  regDate : string = ''
  vehicle = new VehicleDataIn();
  constructor(private toastr: ToastrService, private vehicleService: VehicleService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id');

    if (id && id != "0") {
      this.vehicleService.get(id!).subscribe((response: any) => {
        if (response.status == 200) {
          this.vehicle = response.data;
          this.regDate = this.formatDate(this.vehicle.registrationExpirationDate);
          return;
        }
        else {
          this.toastr.error(response.message);
          return;
        }
      }, (error: any) => {
        this.toastr.error('Došlo je do greške.');
      });
    }
    else {
      this.vehicle = new VehicleDataIn();
      this.vehicle.id = undefined;
      this.regDate = this.formatDate(new Date);
    }
  }

  navigate(route : string){
    this.router.navigate([route]).then(() => {
        window.scrollTo(0, 0);
    });  }

  formatDate(dateInput: Date): string {
    const date = new Date(dateInput)
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  save() {
    this.vehicleService.save(this.vehicle).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message)
        this.navigate('panel/vozila')
        return;
      }
      else {
        this.toastr.error(response.message);
        return;
      }
    }, (error: any) => {
      this.toastr.error('Došlo je do greške.');
    });
  }
}
