<!-- SLIDER -->
<div class="slider">
    <div class="slider-half-1">
        <div class="content">
            <h1 class="title">Najbrža registracija vozila<br> <span class="boldTitle">na Vračaru</span></h1>
            <h3 class="text">U skladu sa Vašim dnevnim obavezama, zakažite termin za tehnički pregled i registraciju vozila online. Završite kompletnu registraciju vozila na jednom mestu bez dodatno utrošenog vremena!
            </h3>
            <div class="button-sucess" (click)="navigate('online-zakazivanje')">ZAKAŽI TERMIN</div>
        </div>
    </div>
    <div class="slider-half-2">
        <img class="slider-man" src="../../../../assets/images/slider-man.png" />
    </div>
</div>

<!-- TEHNICKI PREGLED -->
<div class="tehnicki-us" id="onama">
    <div class="tehnicki-content">
        <div class="tehnicki1">
            <h1 class="section-title">Tehnički pregled <span class="primaryColor">Vozila</span></h1>
            <h3 class="text">Dobrodošli u <b>Tehnički pregled Čuburac</b> - vaš pouzdan partner za brz i bezbedan
                tehnički
                pregled vozila. Naša
                najsavremenija linija omogućava vam da u terminu koji vam najviše odgovara, obavite sve vrste tehničkih
                pregleda - od putničkih i teretnih vozila do motocikala, traktora, autobusa i vozila sa 4X4 pogonom.
                <br /><br />
                Kako biste bili sigurni da je vaše vozilo spremno za put, naš stručni tim garantuje temeljan pregled i
                proveru ispravnosti. U Auto centru Čuburac, shvatamo da je tehnički pregled osnovni preduslov za vašu
                bezbednost u saobraćaju. Zato se trudimo da vam pružimo uslugu najvišeg kvaliteta, uz poštovanje svih
                važnih standarda i propisa.
                <br /><br />
                Neka vaše putovanje bude sigurno i bezbrižno - posetite Tehnički pregled Čuburac i osigurajte se da je
                vaše
                vozilo u skladu sa svim regulativama. Kontaktirajte nas danas da rezervišete termin koji vam odgovara,
                jer vaša bezbednost je naša najveća briga.
            </h3>
        </div>
        <div class="tehnicki2">
            <img class="car-inspection-img" src="../../../../assets/images/car-inspection2.png" />
        </div>
    </div>
</div>

<!-- USLUGE -->
<div class="usluge">
    <div class="usluga-row">
        <span class="title">NAŠE <span class="secondary">USLUGE</span></span>
    </div>
    <div class="usluge">
        <div class="usluga-row">
            <div class="usluga-column" style="background-image: url(../../../../assets/images/reg1.png);">
                <div class="inner-usluga" (click)="navigate('registracija-vozila','izdavanjenalepnica')">
                    <span class="title-usluga">Registracija vozila</span>
                    <span class="content-usluga">Prva registracija, produžetak registracije i izdavanje registarske
                        nalepnice za sve vrste vozila i sva registarska područja bez odlaska u MUP.</span>
                </div>
            </div>
            <div class="usluga-column" style="background-image: url(../../../../assets/images/reg2.png);">
                <div class="inner-usluga" (click)="navigate('tehnicki-pregled','tehnickiauta')">
                    <span class="title-usluga">Tehnički pregled</span>
                    <span class="content-usluga">Brz i siguran tehnički pregled na tri linije, svih vrsta putničkih i
                        teretnih vozila, prikolica, kao i kvadova, električnih vozila i hibrida..</span>
                </div>
            </div>
            <div class="usluga-column" style="background-image: url(../../../../assets/images/reg3.png);">
                <div class="inner-usluga" (click)="navigate('registracija-vozila','prenosvlasnistva')">
                    <span class="title-usluga">Prenos vlasništva i odjava tablica</span>
                    <span class="content-usluga">Izrada kupoprodajnog ugovora i priprema dokumentacije za prenos
                        vlasništva vozila. Odjava vozila u istom danu bez zakazivanja. Pozovite nas za izračunavanje
                        poreza na promet vozila.</span>

                </div>
            </div>
            <div class="usluga-column" style="background-image: url(../../../../assets/images/reg4.png);">
                <div class="inner-usluga" (click)="navigate('registracija-vozila','izdavanjeosaobracajneitablica')">
                    <span class="title-usluga">Izdavanje probnih tablica</span>
                    <span class="content-usluga">Izdavanje probnih tablica za nova i polovna vozila iz uvoza ili
                        odjavljena vozila u najkraćem roku.</span>
                </div>
            </div>
            <div class="usluga-column" style="background-image: url(../../../../assets/images/reg5.png);">
                <div class="inner-usluga" (click)="navigate('osiguranje')">
                    <span class="title-usluga">Osiguranje</span>
                    <span class="content-usluga">Kod nas možete završiti osiguranje od autoodgovornosti, osiguranje
                        putnika u vozilu, putno zdravstveno osiguranje, kao i osiguranje stakala i kasko
                        osiguranje.</span>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- CESTA PITANJA  -->
<!-- <div class="pitanja" id="pitanja">
        <h1 class="section-title">ČESTA PITANJA</h1>
        <app-freq-asked-questions style="width: 85%;"></app-freq-asked-questions>
</div> -->

<!-- KONTAKT -->
<div class="row">
    <h1 class="section-title">KONTAKT</h1>
    <div class="map">
        <div class="iframe">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2831.3149370283736!2d20.47587787618562!3d44.79476997107098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a70711d6d42f3%3A0xde10437e6dc3c50f!2z0JzQsNC60YHQuNC80LAg0JPQvtGA0LrQvtCzIDU5LCDQkdC10L7Qs9GA0LDQtCAxMTAwMA!5e0!3m2!1ssr!2srs!4v1725199549022!5m2!1ssr!2srs"  
              allowfullscreen="" 
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
          
     </div>
    <div class="kontakt" id="kontakt">
            <app-contact-form style="width: 95%;"></app-contact-form>
    </div>
</div>